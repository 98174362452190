:root {
  --blue: #0a0ae7bb;
  --primary: #9dff00;
}

body {
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#about {
  display: flex;
  justify-content: center;
  align-items: center;
}

#about span {
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: fit-content;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: .8rem;
  text-align: center;
  margin-top: 2rem;
}

#services {
  text-align: center;
  margin-top: 2rem;
}

#services li {
  font-size: 1.2rem;
}

#reviews {
  text-align: center;
  margin-top: 2rem;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

header {
  background-color: var(--blue);
  padding: 1rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

nav ul {
  display: flex;
  gap: 1.5rem;
  flex-wrap: nowrap;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  text-decoration: none;
  color: white;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
  white-space: nowrap;
}

nav ul li a:hover {
  background-color: #555;
  border-radius: 4px;
}

.logo {
  font-size: 1.5rem;
  color: var(--primary);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 40px;
  float: left;
}

.hamburger {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  color: white;
}

h2 {
  font-size: 2rem;
}

p {
  font-size: 1.25rem;
}

.material-icons {
  background-color: green;
  border-radius: 20px;
  padding: 5px;
  transition: all .3s;
  margin-right: 10px;
  color: white;
}

.contactpara {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 50px;
  color: white;
  transition: all 1s;
  font-weight: bold;
  background-color: var(--blue);
}

.contactpara a {
  text-decoration: none;
  color: white;
  margin-right: 10px;
}

.contactpara:hover {
  background-color: #555;
  transform: translateY(-5px);
  cursor: pointer;
}

.image-gallery-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}

.image-container {
  text-align: center;
  margin: 10px;
  background-color: var(--blue);
  border: 5px solid white;
  border-radius: 12px;
  padding: 10px;
}

.image-container img {
  width: 500px;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.image-container img:hover {
  transform: scale(1.05);
}

.image-container p {
  margin-top: 5px;
  font-size: 14px;
  color: #ffffff;
}

.nav-links {
  display: none;
}

.desktop-nav span {
  display: flex;
}

@media (max-width: 768px) {
  .logo {
    font-size: 1rem;
  }

  .logo h2 {
    font-size: 1rem;
  }

  .navbar {
    flex-wrap: nowrap;
  }

  .hamburger {
    display: block;
  }

  .desktop-nav {
    display: none;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    background-color: var(--blue);
    position: absolute;
    top: 86px;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links li a {
    padding: 1rem;
    width: 100%;
    color: white;
    text-decoration: none;
  }

  h2 {
    font-size: 1.25rem;
  }

  p {
    font-size: 1rem;
  }

  .image-gallery-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .image-container {
    text-align: center;
    margin: 10px;
    background-color: var(--blue);
    border: 5px solid white;
    border-radius: 12px;
    padding: 10px;
  }
  
  .image-container img {
    width: 250px;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .image-container p {
    margin-top: 5px;
    font-size: 14px;
    color: #ffffff;
  }

}